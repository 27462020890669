<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import { useField } from 'vee-validate';
import {Search} from 'lucide-vue-next'
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const statesStore = useStatesStore();
const PhoneCodes = useDictionaryStore();
const userProfile = useUserProfileStore()

const { t } = useI18n()
const props = defineProps({
  showVerifyLabel: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
  },
  class: {
    type: String as PropType<HTMLAttributes['class']>
  }
})

const phoneMask = computed(() => statesStore.CurrentDialCode?.phone_mask || "(###) ### ####")
const requiredDigitsCount = computed(() => phoneMask.value.replace(/[^#]/g, '').length)

const usedPlaceholder = computed(() => {
  return props.placeholder || statesStore.CurrentDialCode?.phone_mask?.replaceAll('#', '_')
})

const fieldSchema = toTypedSchema(z.string().refine((value) => {
      const rawValue = value.replace(/\D/g, '');
      return rawValue.length === requiredDigitsCount.value;
    }, {
      message: t('Phone number must contain exactly {count} digits', requiredDigitsCount.value), //TODO: Перевод
    }))

const { value: phoneNumber, errorMessage } = useField('phone_number', fieldSchema);

const DialCodes = PhoneCodes.CodePhoneList
const open = ref(false)
const items = ref(DialCodes);
const searchQuery = ref('');
const filteredItems = computed(() => {
  if (!searchQuery.value) {
    return items.value;
  }
  return items.value.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

function applyPhoneMask(value: string, mask: string): string {
  let cleanedValue = value.replace(/\D/g, ''); // Убираем все символы, кроме цифр
  let maskedValue = '';
  let maskIndex = 0;
  let valueIndex = 0;

  while (maskIndex < mask.length && valueIndex < cleanedValue.length) {
    if (mask[maskIndex] === '#') {
      maskedValue += cleanedValue[valueIndex];
      valueIndex++;
    } else {
      maskedValue += mask[maskIndex];
    }
    maskIndex++;
  }

  return maskedValue;
}

const handleInputPhoneNumber = (event: Event) => {
  const inputElement = event.target as HTMLInputElement;

  // Применяем маску к введённому значению
  const rawValue = inputElement.value.replace(/\D/g, ''); // Только цифры
  const maskedValue = applyPhoneMask(rawValue, phoneMask.value); // Применяем маску

  inputElement.value = maskedValue; // Обновляем поле ввода с отформатированным значением
  statesStore.UserPhone = rawValue; // Сохраняем "чистое" значение без маски
  phoneNumber.value = rawValue; // Обновляем значение в форме
};
</script>

<template>
  <UiFormField v-slot="{ componentField }" name="phone_number">
    <UiFormItem :class="cn('relative', props.class)">
      <UiFormLabel>
        <div class="flex justify-between items-center mb-1">
          <p>{{ $t("Phone Number") }}</p>
          <template v-if="showVerifyLabel">
            <p v-if="!userProfile.PhoneNumber" class="ml-4 ys-text-verify flex">{{ $t("Verify") }}
              <Icon size="16" name="ri:arrow-right-up-line" />
            </p>
            <p v-if="userProfile.PhoneNumber" class="ml-4 ys-text-verified flex">{{ $t("Verified") }}
              <Icon size="16" name="ri:check-line" />
            </p>
          </template>
        </div>
      </UiFormLabel>
      <UiFormControl>
        <UiInput 
          type="tel" 
          class="ys-form-input pl-28" 
          @input="handleInputPhoneNumber"
          :placeholder="usedPlaceholder" 
          :disabled="disabled"
          v-bind="componentField" 
        />
        <span class="border-r absolute start-0 inset-y-0 flex items-center text-center justify-center px-2 top-3"
          style="font-size: 14px; font-weight: normal; width: 106px">
          <UiButton 
            @click="open = !open" 
            type="button" 
            :class="{ custom: open }" 
            class="dc-selector-button"
            id="menu-button" 
            aria-expanded="true" 
            aria-haspopup="true" 
            style="font-size: 14px"
            >
            <Icon size="18" class="mr-1" :name="'circle-flags:' + statesStore.CurrentDialCode?.flag?.toLowerCase()" />
            {{ statesStore.CurrentDialCode?.dial_code }}
            <Icon size="18" class="text-zinc-800" name="ri:arrow-down-s-line" />
          </UiButton>
        </span>
        <UiFormMessage class="absolute start-6 top-14">
          {{ errorMessage }}
        </UiFormMessage>
      </UiFormControl>
      <UiFormMessage class="absolute start-6 top-14" />
    </UiFormItem>
  </UiFormField>
  <div v-if="open" class="dc-selector-body my-4" role="menu" aria-orientation="vertical" aria-labelledby="menu-button"
    tabindex="-1" style="border-radius: 16px; border: 0;">
    <div class="relative items-center flex text-center justify-center">
      <UiInput v-model="searchQuery" id="search" type="text" placeholder="Search..." class="ys-search-input" />
      <span class="absolute start-2 inset-y-0 flex items-center justify-center px-2">
        <Search class="size-4 text-gray-500" />
      </span>
    </div>
    <div class="px-2">
      <UiSeparator class="mb-1" />
    </div>
    <UiScrollArea style="height: 150px">
      <div class="py-0.5 px-2" v-for="item in filteredItems" @click="open = false" :key="item.dial_code">
        <p class="locales_list text-gray-700 px-1 py-2 text-sm cursor-pointer flex items-center text-center "
          role="menuitem" tabindex="-1" @click="statesStore.CurrentDialCode = item">
          <Icon size="18" class="mr-2" :name="'circle-flags:' + item?.flag?.toLowerCase()" />
          {{ item.name }}
        </p>
      </div>
    </UiScrollArea>
  </div>
</template>

<style scoped lang="scss">
.dc-selector-button {
  border: 0;
  color: rgba(82, 88, 102, 1);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 0 8px 0;
  gap: 4px;
}

.dc-selector-body {
  width: 376px;
  height: 200px;
  padding: 8px 0 0 0;
  gap: 4px;
  border-radius: 16px;
  border: 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 16px 40px -8px rgba(88, 92, 95, 0.16);
  color: black;

}

.ys-search-input {
  width: 360px;
  height: 36px;
  padding: 8px 10px 10px 30px;
  gap: 8px;
  border-radius: 8px;
  border: none;
}

.ys-search-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.ys-text-verified {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  color: rgba(45, 159, 117, 1);
}

.ys-text-verify {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: right;
  color: rgba(242, 174, 64, 1);
}

</style>